import Aos from 'aos'
import { Suspense, lazy, useEffect } from 'react'

import { Navbar } from '@app/components/Navbar'
import { Footer } from '@app/components/Footer'

import 'aos/dist/aos.css'

import { Guide } from '@app/components/Guide'

/** Describes duration aos effects time in milliseconds. */
const DURATION_TIME: number = 1500

const HowToUse = () => {
    useEffect(() => {
        Aos.init({
            duration: DURATION_TIME,
        })
    }, [])

    return (
        <Suspense fallback="">
            <Navbar />
            <main className="main">
                <Guide />
            </main>
            <Suspense fallback="">
                <Footer />
            </Suspense>
        </Suspense>
    )
}

export default HowToUse
