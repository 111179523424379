import Aos from 'aos'
import { Suspense, lazy, useEffect } from 'react'

import { Navbar } from '@app/components/Navbar'
import { Footer } from '@app/components/Footer'

import 'aos/dist/aos.css'
import { Questions } from '@app/components/Questions'

/** Describes duration aos effects time in milliseconds. */
const DURATION_TIME: number = 1500

const Faq = () => {
    useEffect(() => {
        Aos.init({
            duration: DURATION_TIME,
        })
    }, [])

    return (
        <Suspense fallback="">
            <Navbar />
            <main className="main">
                <Questions />
            </main>
            <Suspense fallback="">
                <Footer />
            </Suspense>
        </Suspense>
    )
}

export default Faq
