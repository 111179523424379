import { SelectChain } from '../common/SelectChain';
import { TokenInfo } from '../common/TokenInfo';

import binance from '@static/images/binance.svg';
import dropdown from '@static/images/dropdown.svg';
import eth from '@static/images/eth.svg';
import polygon from '@static/images/polygon.svg';
import settings from '@static/images/settings.svg';
import swap from '@static/images/swap.svg';
import halborn from '@static/images/halborn.svg';
import hacken from '@static/images/hacken.svg'

import './index.scss';

export const Home: React.FC = () => {
    return <section className="home">
        <div className="home__shape"></div>
        <div className="home__shape-bottom"></div>
        <div className="home__wrapper">
            <div className="home__info">
                <h1 className="home__info__title">Safe and secure cross-chain bridge</h1>
                <span className="home__info__description">Connecting blockchains, securing assets, and enabling interoperability with our cutting-edge cross-chain bridge solution.</span>
                <div className="home__info__buttons">
                    <a
                        href="https://bridge.tricorn.network/"
                        className="home__info__buttons__launch"
                        target="_blank"
                    >Launch App</a>
                    <a href="#about" className="home__info__buttons__more">Learn More</a>
                </div>
            </div>
            <div className="home__bridge">
                <span className="top"></span>
                <span className="right"></span>
                <span className="bottom"></span>
                <span className="left"></span>
                <div className="home__bridge__header">
                    <span className="home__bridge__header__title">Bridge</span>
                    <img className="home__bridge__header__settings-icon" src={settings} alt="settings" />
                </div>
                <SelectChain logo={polygon} name={'Polygon'} label={'From'} />
                <TokenInfo amount={'521.26'} />
                <div className="home__bridge__swap__divider"></div>
                <div className="home__bridge__swap">
                    <img loading="lazy" className="home__bridge__swap__icon" src={swap} alt="swap icon" />
                </div>
                <SelectChain logo={binance} name={'Binance'} label={'To'} />
                <TokenInfo amount={'0.00'} />
                <div className="home__bridge__item">
                    <span className="home__bridge__item__receive-title">You will receive:</span>
                    <img loading="lazy" className="home__bridge__item__receive-eth-icon" src={eth} alt="eth icon" />
                    <span className="home__bridge__item__receive-value">0 wETH</span>
                    <img loading="lazy" className="home__bridge__item__dropdown-icon" src={dropdown} alt="dropdown" />
                </div>
            </div>
            <div className="home__audited" >
                <span className="home__audited__title">AUDITED BY</span>
                <div className="home__audited__icons-wrapper">
                    <a href="https://github.com/HalbornSecurity/PublicReports/blob/master/Cosmos%20Audits/BoostyLabs_Tricorn_Bridge_Server_Golang_Security_Assessment_Report_Halborn_Final.pdf" target="_blank">
                        <img loading="lazy" className="home__audited__icon" src={halborn} alt="halborn" />
                    </a>
                    <a href="https://drive.google.com/file/d/1mnDHAGEgdh9agG7oM_4xULVZeA9TpBtN/view" target="_blank">
                        <img loading="lazy" className="home__audited__icon home__audited__icon-hacken" src={hacken} alt="hacken" />
                    </a>
                </div>
            </div>
            <div className="home__info__buttons-mobile">
                <a href="https://bridge.tricorn.network/" target="_blank" className="home__info__buttons-mobile__launch">Launch App</a>
                <a href="#about" className="home__info__buttons-mobile__more">Learn More</a>
            </div>
        </div>
    </section>
};
