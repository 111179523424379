import Aos from 'aos';
import { Suspense, lazy, useEffect } from 'react';

import { Home } from './components/Home';
import { Navbar } from '@app/components/Navbar';
import { Footer } from './components/Footer';

const LazyVision = lazy(() => import('./components/Vision'));
const LazyBackedBy = lazy(() => import('./components/BackedBy'));
const LazyCommunity = lazy(() => import('./components/Community'));
const LazyHowItWorks = lazy(() => import('./components/HowItWorks'));
const LazyEcosystem = lazy(() => import('./components/Ecosystem'));
const LazyPartners = lazy(() => import('./components/Partners'));
const LazySchedule = lazy(() => import('./components/Schedule'));
const LazyTeam = lazy(() => import('./components/Team'));
const LazyRoadmap = lazy(() => import('./components/Roadmap'));

import 'aos/dist/aos.css';

/** Describes duration aos effects time in milliseconds. */
const DURATION_TIME: number = 1500;

const App = () => {
    useEffect(() => {
        Aos.init({
            duration: DURATION_TIME,
        });
    }, []);

    return <Suspense fallback="">
        <Navbar />
        <main className="main">
            <Suspense fallback="">
                <Home />
            </Suspense>
            <Suspense fallback="">
                <LazyBackedBy />
            </Suspense>
            <Suspense fallback="">
                <LazyVision />
            </Suspense>
            <Suspense fallback="">
                <LazyHowItWorks />
            </Suspense>
            <Suspense fallback="">
                <LazyEcosystem />
            </Suspense>
            <Suspense fallback="">
                <LazyPartners />
            </Suspense>
            <Suspense fallback="">
                <LazySchedule />
            </Suspense>
            {/* <LazyTeam /> */}
        </main>
        {/* <Suspense fallback="">
            <LazyRoadmap />
        </Suspense> */}
        <main className="main-bottom">
            <Suspense fallback="">
                <LazyCommunity />
            </Suspense>
        </main>
        <Suspense fallback="">
            <Footer />
        </Suspense>
    </Suspense>;
}

export default App;
