// eslint-disable-next-line
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './app/App';

import 'aos/dist/aos.css';
import './index.scss';
import HowToUse from '@app/routes/HowToUse';
import Faq from '@app/routes/Faq';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    { path: '/', element: <App /> },
    { path: '/how-to-use', element: <HowToUse /> },
    { path: '/faq', element: <Faq /> },
])

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
