import dropdown from '@static/images/dropdown.svg';

type SelectChainProps = {
    logo: string,
    name: string,
    label: string
}

export const SelectChain: React.FC<SelectChainProps> = ({ logo, name, label }) => {
    return <>
        <label htmlFor="chain">{label}</label>
        <div className="home__bridge__item" id={`chain ${name}`}>
            <img loading="lazy" className="home__bridge__item__logo" src={logo} alt="chain" />
            <div className="home__bridge__item__info">
                <span className="home__bridge__item__info__title">{name}</span>
                <span className="home__bridge__item__info__subtitle">Blockchain</span>
            </div>
            <img loading="lazy" className="home__bridge__item__dropdown-icon" src={dropdown} alt="dropdown" />
        </div>
    </>
};
