import dropdown from '@static/images/dropdown.svg';
import eth from '@static/images/eth.svg';

type TokenInfoProps = {
    amount: string,
}

export const TokenInfo: React.FC<TokenInfoProps> = ({ amount }) => {
    return <div className="home__bridge__item">
        <div className="home__bridge__item__amount">
            <span className="title">Amount</span>
            <span className="value">0.00</span>
        </div>
        <div className="home__bridge__item__max">
            <div className="max">MAX</div>
            <span className="max-value">{amount}</span>
        </div>
        <div className="home__bridge__item__token">
            <span className="home__bridge__item__token__title">Token</span>
            <div className="home__bridge__item__token__info">
                <img loading="lazy" className="token-icon" src={eth} alt="eth" />
                <span className="token-name">ETH</span>
            </div>
        </div>
        <img className="home__bridge__item__dropdown-icon" src={dropdown} alt="dropdown" />
    </div>
};
